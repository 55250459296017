import Client1 from '../../assets/image/saas/testimonial/client-1.jpg';
import Client2 from '../../assets/image/agency/client/denny.png';

export const Faq = [
  {
    id: 1,
    expend: true,
    title: "L'outil de Business Plan StartMyStory est-il totalement gratuit ?",
    description:
      'Oui si vous utilisez l’outil StartMyStory by Banque Populaire. ' +
      'Vous ne déboursez pas un euro pour utiliser l’interface de business plan.' +
      ' L’outil est gratuit et le restera ! ' +
      'La gratuité de l’outil s’intègre dans la volonté de Banque Populaire de soutenir ' +
      'la création d’entreprise grâce à un outil accessible à tous et facile à utiliser.',
  },
  {
    id: 2,
    title:
      "L'outil de Business Plan est-il utilisable sans formation comptable ?\n",
    description:
      'Oui. Et justement, il est destiné à des créateurs n’ayant pas ou peu de connaissances comptables. Il est un outil d’aide à la prise de décision par rapport à des objectifs chiffrés. Vous êtes accompagné tout au long de votre projet par les infos bulles dans la partie saisie.',
  },
  {
    id: 3,
    title: 'Combien de temps faut-il pour réaliser un business plan ?',
    description:
      'Si vous bénéficiez de toutes les informations nécessaires à la construction d’un business plan, le remplir ne prendra que quelques heures. \n' +
      'Il est cependant assez rare d’avoir toutes ces informations en même temps. Avec StartMyStory vous pouvez accéder à votre business plan en ligne autant de fois que vous le voulez et quand vous voulez. \n' +
      'N’hésitez pas à prendre le temps de construire étape par étape votre plan d’affaires',
  },
  {
    id: 4,
    title:
      "Sur combien d'exercices comptable puis-je réaliser mon business plan ?",
    description:
      'Le business plan vous est proposé sur trois années d’exercice par défaut. \n' +
      "Il n'est pas possible de choisir le nombre de mois pour son premier exercice. Pour contourner cela, vous pouvez lisser votre exercice sur 12 mois, ou sur le nombre de mois nécessaires.",
  },
  {
    id: 4,
    title: 'Est-ce que la confidentialité de mes données est assurée ?',
    description:
      'Nous prenons le plus grand soin à assurer la confidentialité et la sécurité des données. \n' +
      "Nos serveurs sont des machines dédiées, sécurisées et gérées par des hébergeurs professionnels, et rompus aux questions de confidentialité et de sécurité. Les serveurs sont bien sûr protégés contre les coupures d'électricité et situés dans des salles où les accès sont rigoureusement contrôlés. Nous effectuons une sauvegarde de l'ensemble des données chaque nuit afin qu'en cas d'incident grave nous puissions restaurer les données de la journée précédente. L’accès à vos données est protégé par un identifiant et un mot de passe cryptés.",
  },
];

export const Features = [
  {
    id: 1,
    icon: 'flaticon-creative',
    title: "100% Créateur d'entreprise",
    description:
      'Une fois les premiers postes de prévisions de ventes ou de charges saisis, le tableau de bord de votre activité commence à donner vie à votre entreprise.',
  },
  {
    id: 2,
    icon: 'flaticon-briefing',
    title: "100% Gestion d'entreprise",
    description:
      'Enfin une application professionnelle de business plan adaptée à votre métier !' +
      '\n' +
      'Fini les calculs interminables dans des feuilles excel, les oublis ou les erreurs de charges sociales et d’impôts …',
  },
  {
    id: 3,
    icon: 'flaticon-flask',
    title: '100% Sur-mesure',
    description:
      'Votre entreprise est unique… Votre business plan l’est également !' +
      '\n' +
      'Vous pouvez personnaliser très facilement tous les postes de votre activité, actuelle ou future.',
  },
];

export const Footer_Data = [
  {
    title: 'Notre partenaire Banque Populaire',
    menuItems: [
      {
        url: 'https://www.banquepopulaire.fr/',
        text: 'Banque Populaire',
      },
      {
        url: 'https://www.banquepopulaire.fr',
        text: 'Contacter un conseiller',
      },
      {
        url: 'https://agences.banquepopulaire.fr/banque-assurance/',
        text: 'Les agences',
      },
    ],
  },
  {
    title: 'Business Plan',
    menuItems: [
      {
        url: 'https://banque-populaire.startmystory.fr',
        text: 'Me connecter',
      },
      {
        url: 'https://banque-populaire.startmystory.fr',
        text: "S'inscrire",
      },
    ],
  },
  {
    title: 'Légales',
    menuItems: [
      {
        url: 'https://www.startmystory.fr/mentions-legales/',
        text: 'Mentions légales',
      },
      {
        url:
          'https://www.startmystory.fr/conditions-generales-de-vente-dutilisation/',
        text: 'CGU',
      },
    ],
  },
];

export const Service = [
  {
    id: 1,
    icon: 'flaticon-briefing',
    title: 'Saisie intuitive des prévisions',
    description:
      'Nous vous proposons par défaut les postes comptables incontournables d’une entreprise ; et calculons pour vous les différentes cotisations et règles comptables.',
  },
  {
    id: 2,
    icon: 'flaticon-trophy',
    title: 'Ajout de postes personnalisés',
    description:
      'À tout moment vous pouvez ajouter ou supprimer de nouveaux postes pour personnaliser entièrement votre business plan à votre activité.',
  },
  {
    id: 3,
    icon: 'flaticon-atom',
    title: 'Indicateurs mis à jour en temps réel',
    description:
      'Consultez en temps réel les impacts financiers de vos choix de gestion. Vous disposez sur la même page de travail de tous les indicateurs de votre activité.',
  },
  {
    id: 4,
    icon: 'flaticon-ruler',
    title: "Tableau de bord d'activité",
    description:
      'Au fil de la saisie de vos prévisions, le tableau de bord de votre activité se construit et vous aide dans votre prise de décision et d’arbitrage budgétaire.',
  },
  {
    id: 5,
    icon: 'flaticon-creative',
    title: 'Personnalisation de votre profil',
    description:
      'Vous pouvez personnaliser votre profil, paramétrer les préférences comptables de votre dossier ; ainsi que les statuts de cotisation de vos futurs salariés et vous-même.',
  },
  {
    id: 6,
    icon: 'flaticon-conversation',
    title: 'Données 100% sécurisées',
    description:
      'Authentification Facebook ou LinkedIn. Les données de votre Business Plan sont cryptées, et couvertes par la protection des données personnelles RGPD.',
  },
];

export const Timeline = [
  {
    title: 'Je crée mon compte',
    description:
      "Facebook ou LinkedIn Connect pour plus de simplicité et de sécurité. Procédez au paramétrage de vos règles comptables... Et c'est parti !",
  },
  {
    title: 'Je complète mes prévisions ',
    description:
      'A partir de la liste des postes existants, complétez sur 3 exercices vos futures Ventes, Charges, Immobilisations, Achats, ou Frais de Personnel',
  },
  {
    title: ' Je consulte mon Tableau de Bord',
    description:
      'Suivi de Trésorerie, Compte de Résultat ou Bilan prévisionnel... Faites évoluer vos prévisions budgétaires en fonction de vos objectifs de performance.',
  },
];

export const Testimonial = [
  {
    id: 1,
    name: 'Michal Andry',
    designation: 'Ceo of Invission Co.',
    comment:
      'Love to work with this designer in every our future project to ensure we are going to build best prototyping features.',
    avatar_url: Client1,
    social_icon: 'flaticon-instagram',
  },
  {
    id: 2,
    name: 'Roman Ul Oman',
    designation: 'Co-founder of QatarDiaries',
    comment:
      'Impressed with master class support of the team and really look forward for the future. A true passionate team.',
    avatar_url: Client2,
    social_icon: 'flaticon-twitter-logo-silhouette',
  },
];

export const MENU_ITEMS = [
  {
    label: 'Accueil',
    path: '#banner_section',
    offset: '70',
  },
  {
    label: 'Services',
    path: '#service_section',
    offset: '70',
  },
  {
    label: 'Fonctionnalités',
    path: '#feature_section',
    offset: '70',
  },
  {
    label: 'FAQ',
    path: '#faq_section',
    offset: '70',
  },
];

export const MONTHLY_PRICING_TABLE = [
  {
    freePlan: true,
    name: 'Basic Account',
    description: 'For Small teams or group who need to build website ',
    price: '$0',
    priceLabel: 'Only for first month',
    buttonLabel: 'CREATE FREE ACCOUNT',
    url: '#',
    listItems: [
      {
        content: 'Drag & Drop Builder',
      },
      {
        content: '1,000s of Templates Ready',
      },
      {
        content: 'Blog Tools',
      },
      {
        content: 'eCommerce Store ',
      },
      {
        content: '30+ Webmaster Tools',
      },
    ],
  },
  {
    name: 'Business Account',
    description: 'For Mediums teams or group who need to build website ',
    price: '$9.87',
    priceLabel: 'Per month & subscription yearly',
    buttonLabel: 'START FREE TRIAL',
    url: '#',
    listItems: [
      {
        content: 'Drag & Drop Builder',
      },
      {
        content: '1,000s of Templates Ready',
      },
      {
        content: 'Blog Tools',
      },
      {
        content: 'eCommerce Store ',
      },
      {
        content: '30+ Webmaster Tools',
      },
    ],
  },
  {
    name: 'Premium Account',
    description: 'For Large teams or group who need to build website ',
    price: '$12.98',
    priceLabel: 'Per month & subscription yearly',
    buttonLabel: 'START FREE TRIAL',
    url: '#',
    listItems: [
      {
        content: 'Drag & Drop Builder',
      },
      {
        content: '1,000s of Templates Ready',
      },
      {
        content: 'Blog Tools',
      },
      {
        content: 'eCommerce Store ',
      },
      {
        content: '30+ Webmaster Tools',
      },
    ],
  },
];

export const YEARLY_PRICING_TABLE = [
  {
    freePlan: true,
    name: 'Basic Account',
    description: 'For a single client or team who need to build website ',
    price: '$0',
    priceLabel: 'Only for first month',
    buttonLabel: 'CREATE FREE ACCOUNT',
    url: '#',
    listItems: [
      {
        content: 'Drag & Drop Builder',
      },
      {
        content: '1,000s of Templates Ready',
      },
      {
        content: 'Blog Tools',
      },
      {
        content: 'eCommerce Store ',
      },
      {
        content: '30+ Webmaster Tools',
      },
    ],
  },
  {
    name: 'Business Account',
    description: 'For Small teams or group who need to build website ',
    price: '$6.00',
    priceLabel: 'Per month & subscription yearly',
    buttonLabel: 'START FREE TRIAL',
    url: '#',
    listItems: [
      {
        content: 'Unlimited secure storage',
      },
      {
        content: '2,000s of Templates Ready',
      },
      {
        content: 'Blog Tools',
      },
      {
        content: '24/7 phone support',
      },
      {
        content: '50+ Webmaster Tools',
      },
    ],
  },
  {
    name: 'Premium Account',
    description: 'For Large teams or group who need to build website ',
    price: '$9.99',
    priceLabel: 'Per month & subscription yearly',
    buttonLabel: 'START FREE TRIAL',
    url: '#',
    listItems: [
      {
        content: 'Drag & Drop Builder',
      },
      {
        content: '3,000s of Templates Ready',
      },
      {
        content: 'Advanced branding',
      },
      {
        content: 'Knowledge base support',
      },
      {
        content: '80+ Webmaster Tools',
      },
    ],
  },
];
